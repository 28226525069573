<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<!-- <a-form-item class="ui-form__item" name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item> -->
					
					<a-form-item label="分类名称" name="title">
						<a-input v-model:value="formState.title" placeholder="请输入分类名称"/>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['mall_setting_classify_add']" type="primary" @click="onAdd">添加分类</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'imgUrl'">
							<a-image :src="record.imgUrl" style="width: 60px;"></a-image>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime)}}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="getChildren(record)">
											<a-menu-item>
												子类列表
											</a-menu-item>
										</div>
										<div v-permission="['mall_setting_classify_edit']" @click="onEdit(record)">
											<a-menu-item>
												修改
											</a-menu-item>
										</div>
										<div v-permission="['mall_setting_classify_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
			<a-modal v-model:visible="childVisible" destroyOnClose title="子类列表" width="850px">
				<template #footer>
					<a-button type="primary" @click="childVisible = false;">关闭</a-button>
				</template>
				<a-spin :spinning="loading">
					<a-form ref="childForm" name="childForm" :model="childFormState" @finish="onChildSearch">
						<a-row>
							<a-form-item label="分类名称" name="title">
								<a-input :maxLength="6" v-model:value="childFormState.title" placeholder="请输入分类名称,最长6个字符"></a-input>
							</a-form-item>
						</a-row>
						<a-row>
							<a-col :span="18">
								<a-button v-permission="['mall_setting_classify_add']" type="primary" @click="onAdd">添加子分类</a-button>
							</a-col>
							<a-col :span="6" style="text-align: right;">
								<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
								<a-button @click="resetChild">重置</a-button>
							</a-col>
						</a-row>
						
						<a-table style="margin-top: 10px;" :columns="columns" :dataSource="childList" :pagination="childPagination">
							<template #bodyCell="{column, record}">
								<template v-if="column.key === 'imgUrl'">
									<a-image :src="record.imgUrl" style="width: 60px;"></a-image>
								</template>
								<template v-if="column.key === 'action'">
									<a-dropdown :trigger="['click', 'hover']">
										<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
										</a-button>
										<template #overlay>
											<a-menu>
												<div v-permission="['mall_setting_classify_edit']" @click="onEdit(record)">
													<a-menu-item>
														修改
													</a-menu-item>
												</div>
												<div v-permission="['mall_setting_classify_delete']" @click="onDelete(record)">
													<a-menu-item>
														删除
													</a-menu-item>
												</div>
											</a-menu>
										</template>
									</a-dropdown>
								</template>
							</template>
						</a-table>
					</a-form>
				</a-spin>
			</a-modal>
			
			<a-modal v-model:visible="showModal" destroyOnClose :title="modelRef.id ? '修改商品分类' : '添加商品分类'" width="750px" @ok="onSubmit">
				<a-spin :spinning="loading">
					<a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm" :labelCol="{span: 5}" :wrapperCol="{span: 15 }">	
						<a-form-item label="分类名称" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input :maxLength="6" v-model:value="modelRef.title" placeholder="请输入分类名称,最长6个字符"/>
						</a-form-item>
						
						<!-- <a-form-item label="分类描述" name="description">
							<a-textarea v-model:value="modelRef.description" placeholder="请输入分类描述"></a-textarea>
						</a-form-item> -->
						
						<a-form-item name="imgUrl" label="图片" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-upload
									v-model:file-list="fileList"
									name="file"
									list-type="picture-card"
									class="avatar-uploader"
									:show-upload-list="false"
									action="/admin/ajaxUpload.do"
									:before-upload="beforeUpload"
									@change="handleChange"
							>
								<img v-if="modelRef.imgUrl" :src="modelRef.imgUrl" alt="avatar" style="width: 100px;height: 100px;"/>
								<div v-else>
									<div class="ant-upload-text">Upload</div>
								</div>
							</a-upload>
						</a-form-item>
						
						<a-form-item label="排序号" name="sort" extra="数字越大，排序越前，如果同级，最新创建的优先级大" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
						</a-form-item>
						
						<!-- <a-form-item label="首页展示" name="isShow" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-switch v-model:checked="modelRef.isShow" checked-children="是" un-checked-children="否"></a-switch>
						</a-form-item> -->
						
						<!-- <a-form-item label="分类展示" name="isPageClassify" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-switch v-model:checked="modelRef.isPageClassify" checked-children="是" un-checked-children="否"></a-switch>
						</a-form-item> -->
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getGoodsClassifyList, deleteGoodsClassify, getGoodsClassifyDetail, saveGoodsClassify, updateGoodsClassify } from '@/service/modules/mall.js';
	export default {
		components: {Icon },
		data() {
			return {
				loading: false,
				formState: {},
				organizationList: [],
				cinemaAllList: [],
				fileList: [],
				searchData: {},
				showModal: false,
				modelRef: {
					// isShow: false,
					// isPageClassify: false,
				},
				list: [],
				columns: [{
					title: '分类名称',
					dataIndex: 'title'
				}, 
				{
					title: '分类图片',
					key: 'imgUrl'
				},
				// {
				// 	title: '分类描述',
				// 	dataIndex: 'description'
				// }, 
				{
					title: '排序号',
					dataIndex: 'sort'
				}, 
				// {
				// 	title: '展示信息',
				// 	dataIndex: 'link'
				// }, 
				{
					title: '操作',
					key: 'action'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				childVisible:false,
				parentData: {},
				childFormState: {},
				searchChildData: {},
				childList: [],
				childPagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getChildren();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getChildren();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getGoodsClassifyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						classifyType: 1,
						parentId: 0,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onChildSearch() {
				this.childPagination.current = 1;
				this.childPagination.pageSize = 10;
				this.searchChildData = JSON.parse(JSON.stringify(this.childFormState));
				this.getChildren();
			},
			resetChild() {
				this.$refs.childForm.resetFields();
				this.onChildSearch();
			},
			async getChildren(record) {
				if (record) {
					this.childFormState = {};
					this.searchChildData = {};
					this.parentData = record;
				}
				this.loading = true;
				try {
					let ret = await getGoodsClassifyList({
						page: this.childPagination.current,
						pageSize: this.childPagination.pageSize,
						parentId: this.parentData.id,
						classifyType: 1,
						...this.searchChildData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.childVisible = true;
						this.childPagination.total = ret.data.totalCount;
						this.childList = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.isShow = postData.isShow ? 1 : 0;
					postData.isPageClassify = postData.isPageClassify ? 1 : 0;
					postData.classifyType = 1;
					let ret;
					this.loading = true;
					try {
						if (this.isEdit) {
							ret = await updateGoodsClassify(postData);
						} else {
							postData.level = this.childVisible ? 2 : 1;
							postData.parentId = this.childVisible ? this.parentData.id : undefined
							ret = await saveGoodsClassify(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$refs.addForm.resetFields();
							this.showModal = false;
							if (this.childVisible) {
								this.getChildren();
							} else {
								this.getData();
							}
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onAdd() {
				this.isEdit = false;
				this.showModal = true;
				this.modelRef = {};
				this.$nextTick(() => {
					this.$refs.addForm.resetFields();
				});
			},
			async onEdit(item) {
				this.loading = true;
				try {
					let ret = await getGoodsClassifyDetail({
						id: item.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.isEdit = true;
						ret.data.isShow = ret.data.isShow ? true : false;
						ret.data.isPageClassify = ret.data.isPageClassify ? true : false;
						this.modelRef = ret.data;
						this.showModal = true;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteGoodsClassify({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								if (this.childVisible) {
									this.getChildren();
								} else {
									this.getData();
								}
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			beforeUpload(file) {
			    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			    if (!isJpgOrPng) {
			        this.$message.error('上传文件格式不正确');
			    }
			    const isLt1M = file.size / 1024 / 1024 < 1;
			    if (!isLt1M) {
			      this.$message.error('上传文件太大了');
			    }
			    return isJpgOrPng && isLt1M;
			},
			handleChange(info) {
			     if (info.file.status === 'done') {
			        //console.log(info.file);
			        this.fileList = info.fileList;
			        const $fileList = [...this.fileList];
			        this.fileList = $fileList.map(file => {
			            if (file.response) {
							this.modelRef.imgUrl = file.response.data.imgUrl;
			                file.url = file.response.data.imgUrl;
			            }
			            return file;
			        });
			    }
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
